import React from 'react';

function Report() {
  return (
    <div>
      <h1>Report</h1>
      <h2>Coming Soon!</h2>
    </div>
  );
}

export default Report;
