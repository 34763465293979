import React from 'react';

function Rank () {
  return (
    <div>
      <h1>Rank Page</h1>
      <h2>Coming Soon!</h2>
    </div>
  );
}

export default Rank;
